import ContentWrapper from '@/components/ContentWrapper';
import RichText from '@/components/RichText';
import { BaseBlockComponentProps } from '@/types/block';
import { NextArray } from '@grace-studio/graceful-next/types';
import type { PortableTextBlock } from '@portabletext/types/dist';

type PreambleBlockProps = BaseBlockComponentProps<{
  text: NextArray<PortableTextBlock>;
  tag?: string;
}>;

const PreambleBlock: PreambleBlockProps = ({ block }) => (
  <ContentWrapper className="grid-base gap-y-4 lg:gap-y-6">
    {block.tag && <span className="col-span-3">{block.tag}</span>}
    <RichText
      className="col-span-full text-bigbase lg:col-span-8 row-start-2 first:[&_p]:indent-44 flex flex-col"
      {...block}
    />
  </ContentWrapper>
);

export default PreambleBlock;
